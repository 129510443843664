body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.custom-paging {
 

  transition: all 0.3s ease-in-out;
}


.ActiveSlider{
  background: #309e82;
  z-index: 99;
  flex-shrink: 0;
  width: 11px;
  height: 11px;
  border-radius: 4.4px;
  cursor: pointer;
  transform: scaleX(2.5);
  
}

.NonActiveSlider{
  background-color:#C4C4C4 ;
  z-index: 99;
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  border-radius: 44px;
  cursor: pointer;

 
}
.NonActiveSlider:hover{
  background-color: #309e82 ;
}
@media (max-width: 480px) {
  .ActiveSlider{
    width: 10px;
    height: 10px;
    border-radius: 3.8px;
  }
  .NonActiveSlider{
    width: 10px;
    height: 10px;
  }

}

