@import url('https://fonts.googleapis.com/css2?family=  +Grotesk:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@300;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap');

*::-webkit-scrollbar {
  display: none;
}

/* landing page Navbar Login Button */
.navbarButton.MuiButton-root.MuiButton-containedPrimary {
  background: #faaa00;
  border-radius: 6px;
  outline: none;
  border: none;
  color: #ffffff;
  padding: 4px 20px;
  width: 77px;
  height: 34px;
  margin-left: 16px;
  font-family: Cormorant;
  font-weight: 700;
  box-shadow: none;
  text-transform: capitalize;
  /* @media (max-width: 500px) {
    display: none;
  } */
}

@media (max-width: 500px) {
  .navbarButton.MuiButton-root.MuiButton-containedPrimary {
    display: none;
  }
}

/* landing page Intro Input Box */
.MuiInputBase-input.MuiOutlinedInput-input {
  color: #fff;
  font-family: 'Hanken Grotesk', sans-serif;
}

/* landing Page mui div input */
@media (max-width: 500px) {
  .MuiFormControl-root.LandingPageSelect {
    width: 100%;
    margin-bottom: 26px;
  }
}

/* Intro Input Box Label */
.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-outlined.MuiFormLabel-colorPrimary.MuiFormLabel-filled.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-outlined {
  color: #fff;
  font-family: Trirong;
  z-index: 1;
}

/* Intro Input box */
.MuiInputBase-root {
  margin-right: 20px;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.99);
}

/* Intro View results button */
.buttonIntro.MuiButton-root.MuiButton-containedPrimary {
  font-family: Trirong;
  font-weight: 600;
  font-size: 14px;
  padding: 8px 20px;
  background-color: rgba(255, 255, 255, 0.99);
  border-radius: 4px;
  margin-top: 16px;
  margin-right: 20px;
  border: none;
  cursor: pointer;
  color: #173535;
  box-shadow: none;
  text-transform: none;
  align-self: flex-end;

  @media (max-width: 480px) {
    margin-top: 0;
  }
}

/* Category button view all button */
.categoryButton.MuiButton-root.MuiButton-containedPrimary {
  padding: 8px 36px 8px 36px;
  border: 1px solid #173535;
  border-radius: 0;
  background-color: #f5f5f5;
  font-weight: 500;
  font-size: 14px;
  color: #173535;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: none;
  font-family: outfit;
  text-transform: capitalize;
}

/* Carousel ViewAll button */
.carouselButton.MuiButton-root.MuiButton-containedPrimary {
  padding: 8px 20px 8px 20px;
  border: 0.960829px solid #173535;
  border-radius: 3.84332px;
  background-color: #f5f5f5;
  font-weight: 500;
  font-size: 14px;
  text-transform: capitalize;
  color: #173535;
  cursor: pointer;
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none;
}

/* landing page image catalog button  */
#imageCatalogButton.MuiButton-root.MuiButton-containedPrimary {
  padding: 8px 14px 8px 14px;
  color: #173535;
  background-color: #feeecc;
  box-shadow: none;
  border-radius: 0;
  width: 128px;
  border: 1px solid #173535;
  font-size: 14px;
  text-transform: capitalize;

  @media (max-width: 480px) {
    display: none !important;
  }
}

/* landing page Mobile screen image catalog button  */
.mobileImageCatalogButton.MuiButton-root.MuiButton-containedPrimary {
  padding: 8px 14px 8px 14px;
  color: #173535;
  background-color: #feeecc;
  box-shadow: none;
  border-radius: 0;
  width: 128px;
  border: 1px solid #173535;
  font-size: 14px;
  text-transform: capitalize;
}

/* Carousel Arrow buttons */
.ArrowButtons.MuiButton-root.MuiButton-containedPrimary {
  background-color: transparent;
  color: #faaa00;
  box-shadow: none;
  border: none;
}

/* Intro Input Label color */
.MuiFormLabel-colorPrimary {
  color: #fff !important;
}

/* product page list view button */
.listView.MuiButton-root.MuiButton-containedPrimary {
  margin-right: 12px;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid #dfdfdf;
  background: #fff;
  color: #4e4e4e;
  box-shadow: none;
  font-size: 14px;
  font-family: Outfit;
  font-weight: 600;
  text-transform: capitalize;

  @media (max-width: 480px) {
    padding: 4px 12px;
    font-size: 12px;
  }
}

/* product page card view button */
.cardView.MuiButton-root.MuiButton-containedPrimary {
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid #dfdfdf;
  background: #fff;
  color: #4e4e4e;
  box-shadow: none;
  font-size: 14px;
  font-family: Outfit;
  font-weight: 600;
  text-transform: capitalize;

  @media (max-width: 480px) {
    padding: 4px 12px;
    font-size: 12px;
  }
}

/* product page selected view button styles */
.selected {
  color: #f4f4f4 !important;
  background-color: #13362d !important;
}

/* Product page Filter Button  */
.filterButton.MuiButton-root.MuiButton-containedPrimary {
  padding: 8px;
  min-width: 0;
  border-radius: 8px;
  box-shadow: 0px 1px 17px 0px rgba(0, 0, 0, 0.08);
}

.LocationIcon,
.StarIcon,
.FaqIcon {
  @media (max-width: 480px) {
    font-size: 16px;
  }
}

/* Pagination Styles for selected button */
.MuiPaginationItem-root.Mui-selected {
  font-weight: 700;
  color: #0a1d3c;
  border-radius: 4px;
  border: 1px solid #0a1d3c;
  background: #fff !important;
}

/* pagination styles for not selected button */
.MuiPaginationItem-page:not(.Mui-selected) {
  border-radius: 4px;
  border: 1px solid #dfe3e8;
  background: #fff;
  color: #80869c;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

/* Product details page verify button */
.verifyButton.MuiButton-root.MuiButton-containedPrimary {
  color: #fff;
  font-size: 14px;
  font-family: Cormorant;
  font-weight: 700;
  border-radius: 6px;
  background: #13362d;
  padding: 4px 20px;
  box-shadow: none;
  width: calc(50% - 12px);
  align-self: flex-end;
  margin: 8px 0;
  text-transform: capitalize;
  width: 100%;

  @media (max-width: 480px) {
    font-size: 13px;
  }
}

.verifyButton.venue.MuiButton-root.MuiButton-containedPrimary {
  color: #fff;
  font-size: 14px;
  font-family: Cormorant;
  font-weight: 700;
  border-radius: 6px;
  background: #13362d;
  padding: 4px 20px;
  box-shadow: none;
  width: calc(50% - 12px);
  align-self: flex-end;
  margin: 8px 0;
  text-transform: capitalize;
  width: 50%;

  @media (max-width: 480px) {
    font-size: 13px;
  }
}

/* Product details page verify button */
.verifyButton.sendMessage.MuiButton-root.MuiButton-containedPrimary {
  font-size: 16px;
  width: 100%;

  @media (max-width: 480px) {
    font-size: 14px;
  }
}

.App {
  overflow-x: hidden;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

select {
  background-position: right 8px center !important;
}

/* Product details page Reviews next/previous button */
.reviewsNextPreviousButtons.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium {
  width: 28px;
  height: 28px;
  padding: 3px 8px 3px 9px;
  border-radius: 6px;
  background: #f9f9f9;
  color: #785343;
  box-shadow: none;
  margin-left: 8px;
}

/*product details browse wedding venues buttons  */
.browseWeddingVenuesButton.MuiButton-root.MuiButton-containedPrimary {
  border-radius: 3.843px;
  border: 0.961px solid #173535;
  background: #fff;
  padding: 8px 36px 8px 36px;
  box-shadow: none;
  color: #173535;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;

  @media (max-width: 480px) {
    display: none;
  }
}

/* product details Modal close button */
.closeButton.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium {
  width: 28px;
  height: 28px;
  border-radius: 11px;
  background: #f3f3f3;
  float: right;
}

/* Product details page verify modal sign in button */
.verifyButton.sendMessage.signinModalButton {
  font-family: Hanken Grotesk;
  margin: auto;
  width: 84% !important;

  @media (max-width: 480px) {
    font-size: 18px !important;
  }
}

/* MUI grid */
.MuiGrid-root.MuiGrid-container {
  justify-content: start;
}

/* venues page filter  */
.checkBoxFilter.MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.PrivateSwitchBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.Mui-checked {
  color: #faaa00;
}

/* login page signup button */
.signUpButton.MuiButton-root.MuiButton-containedPrimary {
  width: 100%;
  border-radius: 10px;
  background: #13362d;
  box-shadow: 0px 4px 19px 0px rgba(119, 147, 65, 0.3);
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  padding: 8px;
  margin-top: 8px;
  text-transform: initial;

  @media (max-width: 480px) {
    font-size: 10px;
    border-radius: 6px;
  }
}

/* login page signinwith google button */
.signInWithGoogle.MuiButton-root.MuiButton-containedPrimary {
  border-radius: 9px;
  background: #e9f1ff;
  color: #4285f4;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-transform: initial;
  box-shadow: none;
  padding: 12px 20px;
  width: 100%;

  @media (max-width: 480px) {
    border-radius: 6px !important;
    font-size: 13px !important;
    padding: 9px;
  }
}

/* login page signinwith google button */
.signInWithFacebook.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium {
  padding: 12px 16px;
  border-radius: 9px;
  background: #fff;
  margin-left: 16px;

  @media (max-width: 480px) {
    padding: 9px;
    border-radius: 6px;
  }
}

/* mobile screen filter done button */
.filterDone.MuiButton-root.MuiButton-containedPrimary {
  display: none;

  @media (max-width: 480px) {
    display: block;
    float: right;
    box-shadow: none;
    color: #faaa00;
    border: 1px solid #faaa00;
    background: linear-gradient(0deg,
        rgba(250, 170, 0, 0.1) 0%,
        rgba(250, 170, 0, 0.1) 100%),
      #fff;
  }
}

/* ChatPage send Button */
.sendButton.MuiButton-root.MuiButton-containedPrimary {
  padding: 8px 32px;
  box-shadow: none;
  text-transform: capitalize;
  color: #fff;
  text-align: center;
  font-family: Hanken Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  border-radius: 8px;
  background: #396b6b;
  line-height: 24px;
  position: absolute;
  top: calc(50% - 18px);
  right: 40px;

  @media (max-width: 480px) {
    font-size: 14px;
    right: 10px;
  }
}

/* Profile Page Edit Button */
.profileEditButton.MuiButton-root.MuiButton-containedPrimary {
  padding: 8px 24px;
  border-radius: 8px;
  border: 1px solid #13362d;
  background: #fff;
  box-shadow: none;
  color: #13362d;
  text-transform: capitalize;
  font-family: Hanken Grotesk;

  @media (max-width: 480px) {
    display: none;
  }
}

.profileEditButton.mobileButton.MuiButton-root.MuiButton-containedPrimary {
  display: none;

  @media (max-width: 480px) {
    display: block;
    padding: 6px 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
  }
}

.editProfileSubmit.MuiButton-root.MuiButton-containedPrimary {
  padding: 8px 40px;
  border-radius: 4px;
  background: #faaa00;
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  text-transform: capitalize;
  box-shadow: none;
  border: 0;

  @media (max-width: 480px) {
    font-size: 14px;
  }
}

/* Book Button ChatPage */
.bookButton.MuiButton-root.MuiButton-containedPrimary {
  padding: 6px 32px;
  box-shadow: none;
  border-radius: 8px;
  border: 1px solid #396b6b;
  background-color: #fff;
  color: #396b6b;
  text-transform: capitalize;
}

/* vendor page heading view all button */
.vendorViewAll.MuiButton-root.MuiButton-containedPrimary {
  color: #fff;
  font-family: Hanken Grotesk;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 8px 16px;
  background-color: #13362d;
  text-transform: capitalize;
  box-shadow: none;
}

/* chat back button */
.chatBackButton.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium {
  color: #173535;
}

.LoginSocialGoogle {
  width: 100%;
}

.MuiBreadcrumbs-ol {
  display: flex;
  align-items: center;
}

.MuiCheckbox-root.MuiCheckbox-colorPrimary {
  color: #c0c6de;
}

/* Review modal close button */
.reviewClose.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium {
  position: absolute;
  right: 0;
  padding: 20px;
  color: #fff;
}

/* Landing page search category , city */
.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters {
  padding: 8px 10px;
  color: rgba(6, 17, 10, 0.5);
  font-family: Hanken Grotesk;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin: 0px 8px 0px 8px;
}

/* Landing page search category , city */
.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected {
  color: #06110a;
  font-family: Hanken Grotesk;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  /* 150% */
  border-radius: 4px;
  background: #e2e6e5;
}

/* landing page select dropdown icon */
.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined {
  display: none;
}

/* Landing page Navbar searchbar */
.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.MuiFormLabel-colorPrimary.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined {
  color: #a1a2a4 !important;
  font-family: Hanken Grotesk;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  top: -2px;
}

/* Landing page Navbar searchbar */
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiAutocomplete-inputRoot {
  border-radius: 20px;
  border: 1px solid #dedfe1;
  background: #fff;
  box-shadow: none;
  padding: 4px 20px 4px 24px;
  display: flex;
  align-items: center;
}

/* Landing page Navbar searchbar */
.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputTypeSearch.MuiAutocomplete-input.MuiAutocomplete-inputFocused {
  color: #a1a2a4 !important;
  font-family: Hanken Grotesk;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 28px;
  height: 4px;
  background-color: #173535;
  cursor: pointer;
}

.loginBackButton.MuiButton-root.MuiButton-containedPrimary {
  position: absolute;
  left: 20px;
  top: 20px;
  text-transform: capitalize;
  border-radius: 10px;
  background: #13362d;
  box-shadow: 0px 4px 19px 0px rgba(119, 147, 65, 0.3);
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  padding: 6px 20px;
}

.viewContact.MuiButton-root.MuiButton-outlinedPrimary {
  color: #13362d;
  font-family: Cormorant;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  border-radius: 6px;
  border: 1px solid #13362d;
  line-height: 26px;
  text-transform: capitalize;
}

@media (max-width: 480px) {
  .viewContact.MuiButton-root.MuiButton-outlinedPrimary {
    height: 32px;
    font-size: 13px;
  }
}

@media (max-width: 600px) {
  #MOBILE_NAVBAR_AUTOCOMPLETE {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
  }
}

.buttonBox {
  /* border:2px solid red; */
  width: 550px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}



.buttonArrow {
  border: 0;
  background-color: aquamarine;
  font-size: 40px;
  cursor: pointer;
  border-radius: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  text-align: center;
  width: 65px;
}

.buttonArrow:active {
  transform: scale(0.96);
}